import { render, staticRenderFns } from "./html/buttons_links.html?vue&type=template&id=51bbbd9c&scoped=true&"
import script from "./js/buttons_links.js?vue&type=script&lang=js&"
export * from "./js/buttons_links.js?vue&type=script&lang=js&"
import style0 from "./css/buttons_links.css?vue&type=style&index=0&id=51bbbd9c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51bbbd9c",
  null
  
)

export default component.exports